import { Animate } from './Proxar/Animate';
import { ContactForm } from './Proxar/ContactForm';

export class Proxar {

    animate: Animate;
    contactForm: ContactForm;

    clickCallbacks: Array<Function>;
    scrollCallbacks: Array<Function>;
    
    /**
     * 
     */
    constructor() {
        this.animate = new Animate();
        this.contactForm = new ContactForm();
        this.clickCallbacks = [];
        this.scrollCallbacks = [];

        this.registerClickCallbacks();
        this.registerScrollCallbacks();

        this.onScrollEvent();

        document.querySelectorAll('.onclick').forEach(element => {
            element.addEventListener('click', (e) => this.onClickEvent(e.target as HTMLElement));
        });
    }
    
    /**
     * 
     */
    private registerClickCallbacks() {
        this.clickCallbacks.push((e: HTMLElement) => { 
            this.animate.toggleFaq(e); 
        });
        this.clickCallbacks.push((e: HTMLElement) => {
            this.animate.toggleMenu(e);
        });
        this.clickCallbacks.push((e: HTMLElement) => {
            this.animate.toggleForm(e);
        });
        this.clickCallbacks.push((e: HTMLElement) => {
            this.contactForm.loadRaCaptchaOnClick(e);
        });
    }
    
    /**
     * 
     */
    private registerScrollCallbacks() {
        this.scrollCallbacks.push(() => {
            this.animate.toggleHeaderShadow();
        });
    }

    
    /**
     * 
     */
    public onClickEvent(element: HTMLElement) {
        for (let i = 0; i < this.clickCallbacks.length; i++) {
            if(this.clickCallbacks[i](element)) {
                return;
            }
        }
    }
    
    /**
     * 
     */
    public onScrollEvent() {
        let time = Date.now();
        let wait = 100;
        window.onscroll = (event) => {
            if(time + wait < Date.now()) {
                for (let i = 0; i < this.scrollCallbacks.length; i++) {
                    if(this.scrollCallbacks[i](event)) {
                        return;
                    }
                }
            }
        };
    }
}