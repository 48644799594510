export class Animate {

    readonly IS_VISIBLE = 'is-v';
    readonly ACTIVE = 'active';
    readonly SCROLL = 'scroll';

    elementsToAnimate: Array<Element>;
    header: HTMLElement | null;


    constructor() {
        this.header = document.querySelector('header');
        this.toggleHeaderShadow();
        if(document.querySelector('.detailed-services')) {
            this.detailedServices();
        }
    }

    private detailedServices() {
        document.querySelectorAll('.detailed-services ul li').forEach((e, i) => {
            e.addEventListener('click', (event) => { 
                document.querySelectorAll('.detailed-services ul li').forEach((e, i) => {
                    if(e == event.target) {
                        e.classList.add(this.ACTIVE);
                        document.querySelectorAll('.detailed-services .text').forEach((t, j) => {
                            if(i == j) {
                                t.classList.add(this.ACTIVE);
                            } else {
                                t.classList.remove(this.ACTIVE);
                            }
                        });
                    } else {
                        e.classList.remove(this.ACTIVE);
                    }
                });
            });
        });
    }
    /**
     * @param  {PointerEvent} event
     * @returns boolean
     */
    public toggleFaq(element: HTMLElement): boolean {
        // @ts-ignore: Unreachable code error
        let target = element.closest('.faq li');
        if(target) {
            let hide = target.classList.contains(this.IS_VISIBLE);
            document.querySelectorAll('.faq li.' + this.IS_VISIBLE).forEach((li) => {
                li.classList.remove(this.IS_VISIBLE);
            });
            if(hide != true) {
                target.classList.add(this.IS_VISIBLE);
            }

            return true;
        }

        return false;
    }
    /**
     * @param  {PointerEvent} event
     * @returns boolean
     */
    public toggleMenu(elelent: HTMLElement):boolean {
        // @ts-ignore: Unreachable code error
        let target = elelent.closest('.menu-button');
        if(target) { 
            let prefix = '';
            if(window.innerWidth < 992) {
                prefix = 'mobile-';
            }

            let menu = document.querySelector('div.' + prefix + 'menu-overlay');
            if(menu) {
                menu.classList.toggle(this.IS_VISIBLE);
                target.classList.toggle(this.ACTIVE);
            }

            return true;
        }
        return false;
    }

    public toggleForm(elelent: HTMLElement):boolean {
        // @ts-ignore: Unreachable code error
        let target = elelent.classList.contains('contact-link');
        if(target) { 
            let overlay = document.querySelector('div.contact-form-box') as HTMLElement;
            if(overlay) {
                overlay.classList.toggle(this.IS_VISIBLE);
                overlay.querySelector('img').style.display = 'block';
            }

            return true;
        }
        return false;
    }

    /**
     */
    public toggleHeaderShadow() {
        if(this.header) {
            if(window.pageYOffset == 0) {
                this.header.classList.remove(this.SCROLL);
            } else if(this.header.classList.contains(this.SCROLL) == false) {
                this.header.classList.add(this.SCROLL);
            }
        }
    }
}