import { Animate } from './Proxar/Animate';
import { ContactForm } from './Proxar/ContactForm';

export class Proxar {
    COOCKIE_CONSENT = 'coocke_consent';
    GTM_ACCEPTED = 'gtm_accepted';

    animate: Animate;
    contactForm: ContactForm;

    clickCallbacks: Array<Function>;
    scrollCallbacks: Array<Function>;
    
    /**
     * 
     */
    constructor() {
        this.animate = new Animate();
        this.contactForm = new ContactForm();
        this.clickCallbacks = [];
        this.scrollCallbacks = [];

        this.registerClickCallbacks();
        this.registerScrollCallbacks();

        this.onScrollEvent();

        document.querySelectorAll('.onclick').forEach(element => {
            element.addEventListener('click', (e) => this.onClickEvent(e.target as HTMLElement));
        });
        
        const consent = document.querySelector('#coockie-consent');
        if(consent) {
            this.processCoockieConsent(consent as HTMLElement);
        }
    }
    
    /**
     * 
     */
    private registerClickCallbacks() {
        this.clickCallbacks.push((e: HTMLElement) => { 
            this.animate.toggleFaq(e); 
        });
        this.clickCallbacks.push((e: HTMLElement) => {
            this.animate.toggleMenu(e);
        });
        this.clickCallbacks.push((e: HTMLElement) => {
            this.animate.toggleForm(e);
        });
        this.clickCallbacks.push((e: HTMLElement) => {
            this.contactForm.loadRaCaptchaOnClick(e);
        });
    }
    
    /**
     * 
     */
    private registerScrollCallbacks() {
        this.scrollCallbacks.push(() => {
            this.animate.toggleHeaderShadow();
        });
    }

    
    /**
     * 
     */
    public onClickEvent(element: HTMLElement) {
        for (let i = 0; i < this.clickCallbacks.length; i++) {
            if(this.clickCallbacks[i](element)) {
                return;
            }
        }
    }
    
    /**
     * 
     */
    public onScrollEvent() {
        let time = Date.now();
        let wait = 100;
        window.onscroll = (event) => {
            if(time + wait < Date.now()) {
                for (let i = 0; i < this.scrollCallbacks.length; i++) {
                    if(this.scrollCallbacks[i](event)) {
                        return;
                    }
                }
            }
        };
    }
    
    public processCoockieConsent(consent: HTMLElement) {
        const coockie = this.getCookie(this.COOCKIE_CONSENT);
        if(coockie === '') {
            consent.style.display = 'grid';
            consent.querySelector('#accept-consent')?.addEventListener('click', () => {
                const gtmInput = (consent.querySelector('#gtm') as HTMLInputElement).checked;
                if(gtmInput) {
                    this.initGTM();
                }
                this.setCookie(this.GTM_ACCEPTED, gtmInput ? 1 : 0, 30);
                this.setCookie(this.COOCKIE_CONSENT, 1, 30);
                consent.style.display = 'none';
            });
            consent.querySelector('#more-options')?.addEventListener('click', () => {
                const moreOptions = consent.querySelector('.more-options');
                if(moreOptions) {
                    moreOptions.classList.add("d-block");
                }
            });
        } else {
            const gtmAccepted = this.getCookie(this.GTM_ACCEPTED);
            if(gtmAccepted == '1') {
                this.initGTM();
            }
        }
    }

    private initGTM() {
        (window as any).init_gtm && (window as any).init_gtm()
    }

    private setCookie(cname: string, cvalue: number, exdays: number) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    
    // Read cookie
    private getCookie(cname: string) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}